$(document).ready(function () {
  var currentPage = 1;
  var quantityPostsPage = 6;

  function getDataForm (append) {
    if(!append){
      currentPage = 1;
    }
    var categories = [];
    var query = $('#query').val();

    $('.category[data-selected="true"]').each(function () {
      var categoryFilter = $(this).data('filter');
      categories.push(categoryFilter);
    });

    return {
      action: 'get_posts_search',
      paged: currentPage,
      posts_per_page: quantityPostsPage,
      categories: categories,
      query: query
    };
  }

  function resetPageContainerAndCounter(){
    currentPage = 1;
    $('#ajax').html('');
  }

  function loadContent (append = false) {

    const noResultsHtml = $('.no-results');

    $.ajax({
      type: 'post',
      url: '/wp/wp-admin/admin-ajax.php',
      data: getDataForm(append),
      dataType: 'json',      
      beforeSend: function (xhr) {
        $('#blog-btn-load-more, #blog-btn-search').attr('disabled', true);
        $('#ajax').addClass('loading');
        noResultsHtml.hide();
      },
      success: function (results) {
        const container = $('#ajax');
        if(!append) {
          resetPageContainerAndCounter();
        }        

        if(currentPage <= results.max_num_pages) {
          results.posts.forEach(post => {
            container.append(renderPost(post));
          });          
        }

        const lastPage = currentPage === results.max_num_pages;
        const noResults = currentPage === 1 && results.posts.length === 0;

        noResults ? noResultsHtml.show() : noResultsHtml.hide();

        if(lastPage || noResults) {
          $('#blog-btn-load-more').hide();
        }else{
          $('#blog-btn-load-more').show();
          currentPage++;
        }
      },
      error: function (err) {
        console.log('Erro Request: ', err);
      }
    }).done(function (xhr) {
      $('#blog-btn-load-more, #blog-btn-search').attr('disabled', false);
      $('#ajax').removeClass('loading');
    });
  }

  if(window._) {
    _.templateSettings = {
      interpolate: /\{\{(.+?)\}\}/g
    };
  }

  const postTemplate = `<article class='{{className}}' data-blog-category='{{category_id}}'>
                          <p class='post-category'>{{category_name}}</p>
                          <a href='{{url}}'>
                            <div class='post-content'>
                              <div class='post-content-image'>
                                {{thumbnail}}
                                <div class='addthis_inline_share_toolbox' data-url='{{url}}' data-title='{{title}}'></div>
                              </div>
                              <div class='post-content-info'>
                                <div class='post-text'>
                                  <span class='post-title'>{{title}}</span>
                                  <p class='post-excerpt'>{{resume}}</p>
                                </div>
                                <div class='post-date'>
                                  <img src='/app/themes/faros/dist/images/blog/calendar-alt-regular.svg'>
                                  <span>{{date}}</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </article>`;

  function renderPost(post) {
    var compiled = _.template(postTemplate);
    return compiled(post);
  }                        

  function loadMorePosts () {
    $(this).attr('disabled', true);    
    loadContent(true);
  }

  $('#blog-btn-load-more').click(loadMorePosts);

  function loadMorePostsIfChangeCategories () {
    $(this).attr('data-selected', $(this).attr('data-selected') === 'false');
    loadContent();
  }

  $('.category').click(loadMorePostsIfChangeCategories);  

  function submitForm(event) {
    if (window.location.href.indexOf('/blog') > -1) {
      event.preventDefault();
      loadContent();
    }    
  }

  $('#blog-form-search').submit(submitForm);

  $('#blog-btn-search').on('click', function () {
    $('#blog-form-search').trigger('submit');
  });

  $('#blog-btn-load-more').trigger('click');
});
