var SITE_TRANSLATION = {
  'about': {
    'pt-BR': 'Conheça a Faros',
    'en-US': 'Know Faros',
    'es-ES': 'Acerca'
  },
  'services': {
    'pt-BR': 'Nossas Soluções',
    'en-US': 'Our Solutions',
    'es-ES': 'Servicios'
  },
  'blog': {
    'pt-BR': 'Blog',
    'en-US': 'Blog',
    'es-ES': 'Blog'
  },
  'portfolio': {
    'pt-BR': 'Portfólio',
    'en-US': 'Portfolio',
    'es-ES': 'Cartera'
  },
  'clients': {
    'pt-BR': 'Clientes',
    'en-US': 'Clients',
    'es-ES': 'Clientes'
  },
  'oportunity': {
    'pt-BR': 'Oportunidades',
    'en-US': 'Opportunities',
    'es-ES': 'Oportunidades'
  },
  'contact': {
    'pt-BR': 'Contato',
    'en-US': 'Contact',
    'es-ES': 'Contacto'
  },
  'home_slogan': {
    'pt-BR': 'Nosso trabalho é capacitar pessoas, desenvolvendo habilidades e competências capazes de gerar mudanças e impacto significativo nesses profissionais.',
    'en-US': 'Our job is to train people, developing skills and competencies capable of generating change and significant impact on these professionals.',
    'es-ES': 'Nuestro trabajo es capacitar personas, desarrollando habilidades y competencias capaces de generar cambios e impacto significativo en esos profesionales.'
  },
  'about-values-title': {
    'pt-BR': 'Valores',
    'en-US': 'Values',
    'es-ES': 'Valores'
  },
  'about-values-desc': {
    'pt-BR': 'Os produtos customizados da empresa permitem a formação e a capacitação de profissionais de níveis educacionais.',
    'en-US': 'The company´s customized products that allow the training and qualification of professionals of educational levels.',
    'es-ES': 'Los productos personalizados de la empresa permiten la formación y la capacitación de profesionales de niveles educativos.'
  },
  'about-values-details': {
    'pt-BR': `<li>Entregamos com qualidade e no tempo</li>
              <li>Agimos com transparência e ética</li>
              <li>Trabalhamos com o futuro, agora!</li>
              <li>Personificamos para compreender o cliente</li>
              <li>Escutamos, falamos e registramos</li>
              <li>Mensuramos para gerir e promover resultados</li>`,
    'en-US': `<li>We deliver with quality and on time</li>
              <li>We act with transparency and ethics</li>
              <li>We work with the future now!</li>
              <li>Impersonate to understand our customer</li>
              <li>We listen, speak and record</li>
              <li>Measure to manage and deliver results</li>`,
    'es-ES': `<li> Entregamos con calidad y en el tiempo </ li>
              <li> Actuamos con transparencia y ética </ li>
              <li> Trabajamos con el futuro, ahora! </ li>
              <li> Personificamos para comprender al cliente </ li>
              <li> Escuchamos, hablamos y registramos </ li>
              <li> Mediciones para gestionar y promover resultados </ li>`
  },
  'about-whatwedo-title': {
    'pt-BR': 'O que fazemos',
    'en-US': 'What we do',
    'es-ES': 'Lo que hacemos'
  },
  'about-whatwedo-desc': {
    'pt-BR': 'Capacitar pessoas, desenvolver habilidades e competências capazes de gerar mudanças nesses profissionais.',
    'en-US': 'Train people, to develop skills and abilities capable of generating a change in this professionals.',
    'es-ES': 'Capacitar personas, desarrollar habilidades y competencias capaces de generar cambios en esos profesionales.'
  },
  'about-whatwedo-details': {
    'pt-BR': 'Com uma equipe multidisciplinar, que reúne profissionais de educação, tecnologia e comunicação, a Faros Educacional atua, desde 2003, no mercado de educação corporativa, ofertando produtos, serviços e soluções customizadas para atender as necessidades e os objetivos estratégicos dos seus clientes. O foco do nosso trabalho é capacitar pessoas, desenvolvendo habilidades e competências capazes de gerar mudanças e impacto significativo nesses profissionais.',
    'en-US': 'With a multidisciplinary team that brings together education, technology and communication professionals, Faros Educacional has been working in the corporate education market since 2003, offering products, services and customized solutions to meet the needs and strategic objectives of its clients. The focus of our work is to empower people, developing skills and competencies capable of generating change and significant impact on these professionals.',
    'es-ES': 'Con un equipo multidisciplinario, que reúne profesionales de educación, tecnología y comunicación, Faros Educacional actúa desde 2003 en el mercado de educación corporativa, ofreciendo productos, servicios y soluciones personalizadas para atender las necesidades y los objetivos estratégicos de sus clientes. El foco de nuestro trabajo es capacitar a las personas, desarrollando habilidades y competencias capaces de generar cambios e impacto significativo en esos profesionales.'
  },
  'about-method-title': {
    'pt-BR': 'Metodologia',
    'en-US': 'Methodology',
    'es-ES': 'Metodología'
  },
  'about-method-desc': {
    'pt-BR': 'O modelo pedagógico da Faros Educacional busca oferecer o que os profissionais do mercado precisam.',
    'en-US': 'Faros Educational pedagogical model seeks to offer what market professionals need.',
    'es-ES': 'El modelo pedagógico de Faros Educacional busca ofrecer lo que los profesionales del mercado necesitan.'
  },
  'about-method-details': {
    'pt-BR': `<p>
                  O modelo pedagógico da Faros Educacional busca oferecer o que os profissionais precisam. Por isso, é centrado no aprendiz, oportunizando a construção do conhecimento por meio de uma aprendizagem personalizada. Sob essa perspectiva, a Faros Educacional desenvolveu sua própria metodologia para elaboração das ações educacionais:
              </p>
              <ul>
                  <li>
                      <strong>1. Projetar e Definir</strong>
                      <br />Realizar o diagnóstico das necessidades
                      <br />Construir os planejamentos pedagógico e tecnológico
                  </li>
                  <li>
                      <strong>2. Desenvolver </strong>
                      <br />Construção dos conteúdos de aprendizagem Implementação e acompanhamento da solução
                  </li>
                  <li>
                      <strong>3. Promover </strong>
                      <br />Criação e desenvolvimento de plano de marketing e comunicação
                  </li>
                  <li>
                      <strong>4. Medir </strong>
                      <br />Mensuração de indicadores de desempenho
                  </li>
              </ul>`,
    'en-US': `<p>
                Faros Educacional's pedagogical model seeks to offer what professionals need. Therefore, it is focused on the apprentice, providing the construction of knowledge through personalized learning. From this perspective, Faros Educacional developed its own methodology for elaborating educational actions:
              </p>
              <ul>
                  <li>
                      <strong>1. Design and Define</strong>
                      <br />Perform the needs diagnosis. Build pedagogical and technological planning
                  </li>
                  <li>
                    <strong>2. Develop </strong>
                    <br />Building learning content Implementation and monitoring of the solution
                  </li>
                  <li>
                    <strong>3. Promote </strong>
                    <br />Creation and development of marketing and communication plan
                  </li>
                  <li>
                    <strong>4. Measure </strong>
                    <br />Measurement of performance indicators
                  </li>
              </ul>`,
    'es-ES': `<p>
                El modelo pedagógico de Faros Educacional busca ofrecer lo que los profesionales necesitan. Por lo tanto, se enfoca en el aprendiz, proporcionando la construcción del conocimiento a través del aprendizaje personalizado. Desde esta perspectiva, Faros Educacional desarrolló su propia metodología para elaborar acciones educativas:
              </p>
              <ul>
                  <li>
                    <strong> 1. Diseñar y Definir </strong>
                    <br /> Realizar el diagnóstico de necesidades. Construir planificación pedagógica y tecnológica.
                  </li>
                  <li>
                  <strong> 2. Desarrollar </strong>
                  <br /> Creación de contenidos de aprendizaje. Implementación y seguimiento de la solución.
                </li>
                <li>
                  <strong> 3. Promover </strong>
                  <br /> Creación y desarrollo del plan de marketing y comunicación.
                </li>
                <li>
                  <strong> 4. Medir </strong>
                  <br /> Medición de indicadores de desempeño
                </li>
              </ul>`
  },
  'about-prizes-title': {
    'pt-BR': 'Prêmios',
    'en-US': 'Awards',
    'es-ES': 'Premios'
  },
  'about-prizes-desc': {
    'pt-BR': 'Na busca por excelência, a Faros Educacional já conquistou alguns prêmios importantes da área educacional.',
    'en-US': 'In the quest for excellence, Faros Educational has already won some important prizes in the educational area.',
    'es-ES': 'En la búsqueda por excelencia, Faros Educacional ya conquistó algunos premios importantes del área educativa.'
  },
  'about-prizes-details': {
    'pt-BR': `<li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Categoria Design para EaD (1º Lugar), com o projeto Trilhas de Aprendizagem Sebrae.
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Categoria Design para EaD (3º Lugar)
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Categoria Ambientes Virtuais de Aprendizagem (1º Lugar), com a plataforma Lerni
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Categoria Material Didático On-line (3º Lugar), com o projeto Trilhas de Aprendizagem Sebrae
              </li>`,
    'en-US': `<li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Category Design for DL (1st Place), with the Trilhas de Aprendizagem Sebrae project.
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Category Design for DL (3rd Place)
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Category Virtual Learning Environments (1st Place), with the Lerni platform
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Category Didactic Material Online (3rd place), with the Trilhas de Aprendizagem Sebrae project
              </li>`,
    'es-ES': `<li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Categoría Diseño para EaD (1º Lugar), con el proyecto Trilhas de Aprendizagem Sebrae.
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Categoría Diseño para EaD (3º Lugar)
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017</strong>
                  <br />
                  Categoría Ambientes Virtuales de Aprendizaje (1º Lugar), con la plataforma Lerni
              </li>
              <li>
                  <strong>Prêmio ABED Belas Artes 2017 </strong>
                  <br />
                  Categoría Material Didáctico On-line (3º Lugar), con el proyecto Trilhas de Aprendizagem Sebrae
              </li>`
  },
  'services-lms-title': {
    'pt-BR': 'Plataformas de aprendizagem',
    'en-US': 'Learning platforms',
    'es-ES': 'Plataformas de aprendizaje'
  },
  'services-lms-desc': {
    'pt-BR': 'A Faros gerencia o Lerni, uma eficiente plataforma de gestão da aprendizagem desenvolvida para auxiliar na promoção da educação corporativa. O Lerni permite planejar, executar e gerir projetos educacionais. Conectada às novas tendências de mercado, a Faros também desenvolve ambiente de aprendizagem por meio de plataformas Open Source, como o LMS Moodle, em suas mais atualizadas versões.',
    'en-US': 'Faros manages Lerni, an efficient learning management platform developed to assist in the promotion of corporate education.  Lerni allows you to plan, execute and manage educational projects. Connected to new market trends, Faros also develops learning environment through Open Source platforms, such as LMS Moodle, in its most up-to-date versions.',
    'es-ES': 'Faros administra el Lerni, una eficiente plataforma de gestión del aprendizaje desarrollada para ayudar en la promoción de la educación corporativa. Lerni permite planificar, ejecutar y gestionar proyectos educativos. Con las nuevas tendencias de mercado, Faros también desarrolla un entorno de aprendizaje a través de plataformas Open Source, como LMS Moodle, en sus más actualizadas versiones.'
  },
  'services-portals-title': {
    'pt-BR': 'Portais educacionais',
    'en-US': 'Educational Portals',
    'es-ES': 'Portales educativos'
  },
  'services-portals-desc': {
    'pt-BR': 'Quer criar um ambiente virtual de aprendizagem para sua escola de negócios, um site para sua Universidade Corporativa ou área de desenvolvimento de pessoas? Fazemos isso para você. Trata-se de sistemas responsáveis por fazer a gestão educacional dos alunos, desde o seu primeiro acesso, matrícula, tutoria, acompanhamento do desempenho acadêmico e emissão de certificados de conclusão das atividades.',
    'en-US': 'Do you want to create a virtual learning environment for your bussines school, a Website for your Corporative University or people developement area? We do it for you. These are systems responsible for the educational management of students, from their first access, enrollment, mentoring, monitoring of academic performance and issuance of certificates of competitions of activities.',
    'es-ES': '¿Quiere crear un ambiente virtual de aprendizaje para su escuela de negocios, un sitio para su Universidad Corporativa en el área de desarrollo de personas? Lo hacemos para usted. Se trata de sistemas responsables de hacer la gestión educativa de los alumnos, desde su primer acceso, matrícula, tutoría, seguimiento del desempeño académico y emisión de certificados de conclusión de las actividades.'
  },
  'services-programs-title': {
    'pt-BR': 'Programas de capacitação',
    'en-US': 'Training programs',
    'es-ES': 'Programas de capacitación'
  },
  'services-programs-desc': {
    'pt-BR': 'Com base em nossa metodologia, criamos Programas e Cursos alinhados às necessidades do cliente, por meio das mais atuais metodologias de aprendizagem e com atenção especial para questões de acessibilidade. Tais soluções envolvem objetos e recursos digitais de aprendizagem como: videoaulas; tutoriais; audio learnings; blendend learning, mobile learning, entre outros.',
    'en-US': 'Based on our methodology, we create Programs and Courses aligned to the needs of the client, through the most current learning methodologies and with special attention to accessibility issues. Such solutions involve learning objects and digital resources such as videotapes: tutorials: audio learnings, mobile learning, among others.',
    'es-ES': 'Con base en nuestra metodología, creamos Programas y Cursos alineados a las necesidades del cliente, a través de las más actuales metodologías de aprendizaje y con atención especial para cuestiones de accesibilidad. Esas soluciones involucran objetos y recursos digitales de aprendizaje como: videoconferencias; tutoriales; aprendizaje con audio; el aprendizaje móvil y el aprendizaje de idiomas, entre otros.'
  },
  'portfolio-desc': {
    'pt-BR': 'Soluções educacionais pensadas nas necessidades dos nossos clientes',
    'en-US': 'Educational solutions tailored to our customer\'s needs',
    'es-ES': 'Soluciones educacionales pensadas para las necesidades de nuestros clientes'
  },
  'know-more': {
    'pt-BR': 'Saiba Mais',
    'en-US': 'Know more',
    'es-ES': 'Conozca más'
  },
  'know-more-lerni': {
    'pt-BR': 'Conheça também o portal Lerni',
    'en-US': 'Know also our portal Lerni',
    'es-ES': 'Visite también el portal de Lerni'
  },
  'portfolio-objective': {
    'pt-BR': 'Objetivo de aprendizagem:',
    'en-US': 'Learning objective',
    'es-ES': 'Objetivo del aprendizaje'
  },
  'portfolio-what-we-done': {
    'pt-BR': 'O que fizemos:',
    'en-US': 'What we did',
    'es-ES': 'Lo que ya hicimos'
  },
  'portfolio-solucao-de-problemas-title': {
    'pt-BR': 'Curso 2 - Capacidade em Solução de Problemas',
    'en-US': 'Course 2 – Problem Solving Capacity',
    'es-ES': 'Curso 2 - Capacidad de Solución de problemas'
  },
  'portfolio-solucao-de-problemas-objective': {
    'pt-BR': 'Melhorar a capacidade de soluções de problemas e possibilitar o conhecimento de ferramentas gerenciais e os fatores críticos para resultados de excelência no ambiente de trabalho.',
    'en-US': 'To improve problem solving capacity and allow for employees to get to know managing tools and critical factors for the achievement of excellence results in the workplace.',
    'es-ES': 'Mejorar la capacidad para resolver problemas y disponibilizar el conocimiento de las herramientas de gestión y los factores críticos para obtener excelentes resultados en el lugar de trabajo.'
  },
  'portfolio-solucao-de-problemas-strategy': {
    'pt-BR': 'Desenvolvimento de conteúdo em formato HTML, abordando diversos temas relacionados à resolução de problemas, para que fosse possível aprender e mapear os conflitos no ambiente de trabalho, evitando-se danos e a diminuição da produtividade.',
    'en-US': 'We have developed HTML content comprising several themes related to problem solving, so that it could be possible to learn and map conflicts in the workplace, thus avoiding damage and decrease in productivity.',
    'es-ES': 'Elaboración de contenidos en formato HTML, abordando diversos temas relacionados con la resolución de problemas, de manera que fuera posible aprender y mapear conflictos en el entorno laboral, evitando daños y la reducción de la productividad.'
  },
  'portfolio-academy-title': {
    'pt-BR': 'Academia da Excelência',
    'en-US': 'Academy of  Excellence',
    'es-ES': 'Academia de la Excelencia'
  },
  'portfolio-academy-objective': {
    'pt-BR': 'Prover conteúdo diferenciado aos funcionários da CAIXA considerados como perfis de alto-desempenho, estimulando também o compartilhamento de informações e conhecimentos estratégicos para a organização.',
    'en-US': 'Provide differentiated content to CAIXA employees considered as high-performance profiles, also stimulating the sharing of information and strategic knowledge for the organization.',
    'es-ES': 'Proporcionar contenido diferenciado a los funcionarios de la CAIXA considerados como perfiles de alto-rendimiento, estimulando también el compartir informaciones y conocimientos estratégicos para la organización.'
  },
  'portfolio-academy-strategy': {
    'pt-BR': 'Desenvolvemos todo o planejamento educacional, desde a matriz de conteúdo, criação de identidade visual, definição de estratégia <em>gamificada</em> e a implementação de recursos para auxiliar o desenvolvimento dos participantes da solução. Para isso, fizemos uma customização da plataforma Moodle para implementar diversas funcionalidades, tais como área para compartilhamento de informações, interação entre os alunos e até um <em>chatbot</em> para atendimento. ',
    'en-US': 'We develop all educational planning, from the content matrix; creations of visual identity, definition of gamify strategy and the implementation of resources to assist the development of participants of the solution. For this, we made customizations of the Moodle platform to implement several functionalities, such as area for information sharing, interactions between students and even a chatbot for attendance. ',
    'es-ES': 'Desarrollamos toda la planificación educacional, desde la matriz del contenido, creación de identidad visual, definición de la estrategia de la dinámica motivacional lúdica y  la implantación de recursos para ayudar el desarrollo de los participantes en la solución. Para ello, hicimos una personalización de la plataforma Moodle para implementar diversas funcionalidades, tales como áreas para compartir informaciones, interacción entre los alumnos y hasta un chatbot para atendimiento. '
  },
  'portfolio-digital-title': {
    'pt-BR': 'Transformação Digital',
    'en-US': 'Digital Transformation',
    'es-ES': 'Transformación Digital'
  },
  'portfolio-digital-objective': {
    'pt-BR': 'Estimular a cultura da transformação digital e da inovação na empresa.',
    'en-US': 'Stimulate the culture of digital transformation and innovation in the company.',
    'es-ES': 'Estimular la cultura de la transformación digital y de la innovación en la empresa.'
  },
  'portfolio-digital-strategy': {
    'pt-BR': 'Planejamento de conteúdo da solução, dividida em 7 videoaulas.  Assuntos como Inteligência Artificial, Experiência do Usuário e Metodologias Ágeis foram abordados com o apoio de especialistas renomados no mercado, como Martha Gabriel e Tiago Mattos. Os alunos ainda tiveram acessos a apostilas, podcasts e foram alimentados com diversas sugestões de conteúdo, como livros, artigos e outros materiais audiovisuais.',
    'en-US': 'content planning, divided into 7 videotapes. Subjects such as Artificial Intelligence, User Experience and Agile Methodologies were approached with the support of renowned experts in the Market, such as Martha Gabriel and Tiago Mattos. The students still have access to handouts, podcasts and were fed with various suggestions of content, such as books, articles and other audiovisual materials.',
    'es-ES': 'Planificación de contenido de la solución, divididas en 7 videoconferencias. Temas  como Inteligencia Artificial, Experiencia del Usuario y Metodologías Ágiles fueron abordados con el apoyo de expertos renombrados en el mercado, como Martha Gabriel y Tiago Mattos. Los alumnos también tuvieron acceso a apostillas, podcasts así como apoyo con diversas sugerencias de contenido, como libros, artículos y otros materiales audiovisuales.'
  },
  'portfolio-hab-leader-title': {
    'pt-BR': 'Habilidades do Líder',
    'en-US': 'Leader Skills',
    'es-ES': 'Habilidades del Líder'
  },
  'portfolio-hab-leader-objective': {
    'pt-BR': 'Desenvolver competências dentro do contexto atual da gestão de pessoas e processos nos líderes e dirigentes da CAIXA.',
    'en-US': 'Develop competencies within the current context of people and processes in CAIXA leaders and managers.',
    'es-ES': 'Desarrollar competencias dentro del contexto actual de la gestión de personas y  procesos en los líderes y dirigentes de la CAIXA.'
  },
  'portfolio-hab-leader-strategy': {
    'pt-BR': 'O planejamento desta solução esteve alinhado aos objetivos estratégicos da CAIXA para estes públicos. Para isso, foram elencados os seguintes temas de trabalho: Visão Sistêmica; Código de Ética; Criatividade; Comunicação; Gesto de Equipe e Empatia; Gestão de Conflitos; Inteligência Emocional; Administração do Tempo e Feedback. O curso teve como principais objetos de aprendizagens videoaulas, que variaram de formato, contando com entrevistas, filmagens com apresentadores e vídeos no formato <em>draw my life</em>.',
    'en-US': 'The planning of this solution was aligned with the strategic objectives of CAIXA for these audiences. For this, the following work themes were listed: Systemic Vision; Code of Ethics; Creativity; Communication; Team Management and Empathy; Conflict Management; Emotional Intelligence; Time Management and Feedback. The course has as main learning objects video lessons, which varied of format, counting on interviews and videos in format draw my life.',
    'es-ES': 'La planificación de esta solución estuvo alineada a los  objetivos estratégicos de la CAIXA para estos públicos. Para ello, fueron enumerados los siguientes temas de trabajo: Visión Sistemática; Código de Ética; Creatividad; Comunicación; Gestión de Equipo y Empatía; Gestión de Conflictos; Inteligencia Emocional; Administración del Tiempo y Feedback. El curso tuvo como principales objetos de aprendizajes videoconferencias, que variaron de formato, contando con entrevistas, filmaciones con presentadores y videos en el formato draw my life.'
  },
  'portfolio-trilhas-aprendizagem-title': {
    'pt-BR': 'Trilha de Aprendizagem',
    'en-US': 'Learning Track',
    'es-ES': 'Sendero de aprendizaje'
  },
  'portfolio-trilhas-aprendizagem-objective': {
    'pt-BR': 'Capacitar os especialistas da área de Atendimento do Sebrae em todo o Brasil.',
    'en-US': 'To train specialists in the Sebrae Service area throughout Brazil.',
    'es-ES': 'Formar especialistas en el área de Servicio SEBRAE en todo Brasil.'
  },
  'portfolio-trilhas-aprendizagem-strategy': {
    'pt-BR': 'Estratégia gamificada, com desenvolvimento web utilizando-se as linguagens JavaScript Angular 2+ (front-end) e .NET Framework C# (back-end). Conteúdo educacional em formato de atividades e desafios, realizados pelos participantes durante o percurso e os diferentes cenários.',
    'en-US': 'We have developed a gamified strategy with web development using JavaScript Angular 2+ (front-end) and .NET Framework C # (back-end), as well as educational activities and challenges to be carried out by the participants during the course, throughout its different scenarios.',
    'es-ES': 'Estrategia gamificada, con desarrollo web usando JavaScript Angular 2+ (front-end) y .NET Framework C # (back-end). Contenidos educativos en la forma de actividades y desafíos, realizados por los participantes durante el curso y los diferentes escenarios.'
  },
  'portfolio-curso-dcp-title': {
    'pt-BR': 'Curso DCP',
    'en-US': 'DCP Course',
    'es-ES': 'Curso DCP'
  },
  'portfolio-curso-dcp-objective': {
    'pt-BR': 'Capacitar a equipe técnica para o correto uso do equipamento Dynamic Cone Penetrometer (DCP), utilizado para controlar a compactação das valas executadas pela Sabesp.',
    'en-US': 'To train the technical team to correctly operate the Dynamic Cone Penetrometer (DCP), used to control the compaction of trenches opened by Sabesp. ',
    'es-ES': 'Capacitar al equipo técnico para el correcto uso del equipo de Penetrómetro de Cono Dinámico (DCP), utilizado para el control de la compactación de zanjas que realiza SABESP.'
  },
  'portfolio-curso-dcp-strategy': {
    'pt-BR': 'Desenvolvimento de identidade visual e criação de apostila para o curso, além de vídeos com orientações técnicas, promovendo a captação de imagens que orienta a operacionalização do equipamento.',
    'en-US': 'We have developed a visual identity for the course and created course packs, as well as videos with technical guidance, including images, that talk through the operationalization of the equipment.',
    'es-ES': 'Desarrollo de identidad visual y creación de apostillas para el curso, además de videos con orientación técnica, promoviendo la captura de imágenes que oriente la operacionalización del equipo.'
  },
  '': {
    'pt-BR': '',
    'en-US': '',
    'es-ES': ''
  },
  'clients-desc': {
    'pt-BR': 'Conheça os parceiros da Faros Educacional',
    'en-US': 'Know the partners of Faros Educational',
    'es-ES': 'Conozca los socios de Faros Educacional'
  },
  'portfolio-loterias-caixa-title': {
    'pt-BR': 'Loterias CAIXA',
    'en-US': 'CAIXA Lotteries',
    'es-ES': 'Loterías CAIXA'
  },
  'portfolio-sabesp-cultura-title': {
    'pt-BR': 'Cultura Organizacional',
    'en-US': 'Organizational culture',
    'es-ES': 'Cultura Organizacional'
  },
  'portfolio-universidade-apae-title': {
    'pt-BR': 'Aplicativo Moodle Uniapae',
    'en-US': 'Moodle Uniapae App',
    'es-ES': 'Aplicación Moodle Uniapae'
  },
  'portfolio-loterias-caixa-objective': {
    'pt-BR': 'Desenvolver novas habilidades nos empregados CAIXA, gestores da Rede Parceira, Correspondentes CAIXA AQUI e empresários lotéricos. ',
    'en-US': 'To have CAIXA employees, managers from the Partner Network and CAIXA AQUI correspondents developing new skills.',
    'es-ES': 'Desarrollar nuevas habilidades para los empleados de CAIXA, gerentes de la Red de Socios, Corresponsales CAIXA AQUI y empresarios de agencias de loterías.'
  },
  'portfolio-loterias-caixa-strategy': {
    'pt-BR': 'Desenvolvimento e customização de plataforma de inscritos, com tutoria, suporte e gerenciamento dos dados dos cursos, também elaborados pela Faros Educacionais. As soluções contaram com os mais diversos objetos educacionais, como apostilas, videoaulas, cartilhas, infográficos e apresentações digitais. A solução compreendia mais de 80 mil pessoas em todo o Brasil.',
    'en-US': 'We have developed and customized the enrollees’ platform with tutorship, support and management of the course’s data, also elaborated by Faros Educacional. The solution counted on many educational objects, such as course packs, video lessons, primers, infographics and digital presentations, and comprised more than 80 thousand people in Brazil.',
    'es-ES': 'Desarrollo y personalización de la plataforma inscrita, con tutoría, soporte y gestión de los datos del curso, también elaborado por la Faros Educacionales. Las soluciones incluyeron los objetos educativos más diversos, como folletos, lecciones en video, apostillas, infografías y presentaciones digitales. La solución alcanzó? a más de 80 mil personas en todo Brasil.'
  },
  'portfolio-sabesp-cultura-objective': {
    'pt-BR': 'Apresentar os conceitos e pilares da cultura organizacional e levar a compreensão de sua importância para o ambiente de trabalho.',
    'en-US': 'Presentation of the concepts and pillars of the organizational culture and promote a better understanding of its importance for the work environment.',
    'es-ES': 'Presentar los conceptos y pilares de la cultura organizacional y liderar la comprensión de su importancia para el clima laboral.'
  },
  'portfolio-sabesp-cultura-strategy': {
    'pt-BR': 'Desenvolvimento de identidade visual, criação de apostila para o curso e vídeos em diferentes formatos com orientações técnicas. ',
    'en-US': 'Visual identity conception, creation of handouts for the course and videos in different formats with technical guidelines.',
    'es-ES': 'Desarrollo de identidad visual, creación de folletos para el curso y videos en diferentes formatos con lineamientos técnicos.'
  },
  'portfolio-universidade-apae-objective': {
    'pt-BR': 'Possibilitar o consumo dos cursos da Universidade Apae em ambiente mobile e, assim, proporcionar maior mobilidade aos alunos da rede.',
    'en-US': 'To enable the adhesion of Apae University courses in a mobile environment and, thus, provide greater mobility to the network’s students. ',
    'es-ES': 'Permitir el consumo de cursos de la Universidad Apae en un entorno móvil y, así, brindar mayor movilidad a los estudiantes en la red.'
  },
  'portfolio-universidade-apae-strategy': {
    'pt-BR': 'Personalização e customização da plataforma de cursos da Universidade Apae para o formato de aplicativo, atentando-se a todas as questões relacionadas à usabilidade e experiência do usuário. Na solução, é possível tanto fazer a compra de cursos como realizá-los.',
    'en-US': 'We have personalized and customized the Apae University course platform in order to adjust it to the application format, paying attention to all issues related to usability and user experience. In the solution, it is possible to either buy courses or take them.',
    'es-ES': 'Possibilitar la personalización de la plataforma de cursos de la Universidad Apae para el formato de la aplicación, prestando atención a todos los aspectos relacionados con la usabilidad y la experiencia del usuario. En la solución, es posible comprar cursos como tomarlos.'
  },
  'inc-ecommerce-objective': {
    'pt-BR': 'Atualizar e capacitar profissionais, estudantes e interessados na área de saúde, contribuindo para o desenvolvimento de uma postura interdisciplinar e da ética profissional.',
    'en-US': 'Update and train professionals, students and those interested in the health area, contributing to the development of an interdisciplinary posture and professional ethics.',
    'es-ES': 'Actualizar y formar profesionales, estudiantes e interesados ​​en el área de la salud, contribuyendo al desarrollo de una postura interdisciplinar y ética profesional.'
  },
  'inc-ecommerce-strategy': {
    'pt-BR': 'A Faros ficou à frente da criação de cursos voltados para o preenchimento de uma lacuna de desenvolvimento contínuo dos profissionais de saúde. As atividades precisavam ser flexíveis, financeiramente acessíveis e envolventes. O trabalho consistiu na construção da matriz instrucional e dos objetos de aprendizagem (vídeos, HTMLs, entrevistas, podcasts, mapas mentais, PDFs, entre outros) e na elaboração e edição dos conteúdos. Oito cursos foram selecionados, atendendo aos seguintes temas: psiquiatria, saúde mental, comunicação, enfermagem, emergência, cuidados com a pele e gestão hospitalar.',
    'en-US': 'Faros took the lead in creating courses aimed at filling a gap in the continuous development of health professionals. Activities needed to be flexible, affordable, and engaging. The work consisted in building the instructional matrix and learning objects (videos, HTMLs, interviews, podcasts, mind maps, PDFs, among others) and in the development and editing of content. Eight courses were selected, addressing the following themes: psychiatry, mental health, communication, nursing, emergency, skin care and hospital management.',
    'es-ES': 'Faros tomó la iniciativa en la creación de cursos destinados a llenar un vacío en el desarrollo continuo de los profesionales de la salud. Las actividades debían ser flexibles, asequibles y atractivas. El trabajo consistió en la construcción de la matriz instruccional y los objetos de aprendizaje (videos, HTMLs, entrevistas, podcasts, mapas mentales, PDFs, entre otros) y en el desarrollo y edición de contenidos. Se seleccionaron ocho cursos que abordan los siguientes temas: psiquiatría, salud mental, comunicación, enfermería, emergencias, cuidado de la piel y gestión hospitalaria.'
  },
  'sebrae-tiktok-objective': {
    'pt-BR': 'Ensinar os micro e pequenos empreendedores a utilizarem o <strong>TikTok</strong> para divulgar a marca, produtos e serviços, como também criar anúncios na plataforma. ',
    'en-US': 'Teach micro and small entrepreneurs to use TikTok to promote the brand, products and services, as well as create ads on the platform.',
    'es-ES': 'Enseñar a micro y pequeños empresarios a utilizar TikTok para promocionar la marca, productos y servicios, así como crear anuncios en la plataforma.'
  },
  'sebrae-tiktok-strategy': {
    'pt-BR': 'Preparamos artigo e vídeo para o portal do Sebrae, contemplando os principais recursos do <strong>TikTok</strong>. Criamos conteúdo focado nas estratégias do aplicativo para posicionar a marca na plataforma e no vídeo, bem como ensinamos os tipos de anúncios que os empresários podem produzir.',
    'en-US': 'We prepared an article and video for the Sebrae portal, covering the main features of TikTok. We create content focused on app strategies to position the brand on the platform and video, as well as teach the types of ads that entrepreneurs can produce.',
    'es-ES': 'Preparamos un artículo y video para el portal Sebrae, cubriendo las principales características de TikTok. Creamos contenido enfocado en estrategias de app para posicionar la marca en la plataforma y video, así como también enseñamos los tipos de anuncios que pueden producir los emprendedores.'
  },
  '': {
    'pt-BR': '',
    'en-US': '',
    'es-ES': ''
  },
  '': {
    'pt-BR': '',
    'en-US': '',
    'es-ES': ''
  },
  '': {
    'pt-BR': '',
    'en-US': '',
    'es-ES': ''
  },
  '': {
    'pt-BR': '',
    'en-US': '',
    'es-ES': ''
  }
};
