$(document).ready(function () {

  var SPMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
  spOptions = {
    onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
  };

  $('input[name="your-phone"').mask(SPMaskBehavior, spOptions);

  $(window).scroll(function () {
    var scrollPos = $(window).scrollTop();
    if (scrollPos > 500) {
      $('.contact-form').fadeIn();
    } else {
      $('.contact-form').fadeOut();
    }
  });

  $('#btn-call-action').click(function () {
    $('.contact-form').addClass('mobile-full').addClass('open');
    $('#btn-call-action').hide();
    $('#box-call-action').show();
  });

  $('#btn-back').click(function () {
    $('.contact-form').removeClass('mobile-full').removeClass('open');
    $('#btn-call-action').show();
    $('#box-call-action').hide();
  });
});
